import React, { FC, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Input } from "@fluentui/react-components";
import {IStackStyles, IStackTokens, Stack} from "@fluentui/react";

interface HomePageProps {}

const stackTokens: IStackTokens = { childrenGap: 10 };
const stackStyles: IStackStyles = {
  root: {
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    '@media (max-width: 600px)': {
      padding: '10px',
    },
  },
};

const HomePage: FC<HomePageProps> = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const lng = navigator.language;
        i18n.changeLanguage(lng);
    }, []);

    return (
        <Stack styles={stackStyles} tokens={stackTokens}>
            <h1 className="text-center">Login</h1>
            <form>
                <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <Input id="username" type="text" placeholder="Enter username" />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Input id="password" type="password" placeholder="Enter password" />
                </div>
                <Button appearance="primary" type="submit">Submit</Button>
            </form>
        </Stack>
    );
}

export default HomePage;